<script setup lang="ts">
import {Category} from "@shopware-pwa/types";
import {useCategorySearch} from "@shopware-pwa/composables-next";
import {Ref} from "vue";
import LayoutNewsletterSignup from "~/components/layout/LayoutNewsletterSignup.vue";
import useBevcoFetch from "~/composables/useBevcoFetch";

const {t} = useI18n()
const requestUrl = useRequestURL();
const {apiInstance} = useShopwareContext();

const props = defineProps<{
    navigationId: string;
}>();

const route = useRoute();

const {search} = useCategorySearch();
const {data: categoryResponse} = await useAsyncData(
    "cmsNavigation" + props.navigationId,
    async () => {
        return await search(props.navigationId, {
            withCmsAssociations: true,
            query: {
                ...route.query,
            },
        });
    }
);
const {category} = useCategory(categoryResponse as Ref<Category>);

const pageType = category.value.cmsPage?.type;

let name = category.value.name;
provide('pageTitle', name);

let title = category.value.translated?.metaTitle;
let description = category.value.translated?.metaDescription;
let image = category.value.media?.url;

const url = requestUrl.href;

if (pageType == 'product_list') {
    title = title ?? t('listing.meta.title', {'name': name});
    description = description ?? t('listing.meta.description', {'name': name});

    //todo: how to get total products?
    const totalProducts = 0;
    if (totalProducts > 20) {
        description += ' | ' + t('listing.meta.numberOfProducts', {'totalProducts': totalProducts});
    }

    useBevcoFetch(
        apiInstance,
        '/store-api/event/category-page',
        {
            method: "POST",
            body: {
                categoryId: category.value.id,
            }
        }
    );
} else {
    title = title ?? t('landing.meta.title', {'name': name});
    description = description ?? t('landing.meta.description', {'name': name});
}

useSeoMeta({
    title: title,
    description: description,
    ogUrl: url,
    ogTitle: title,
    ogDescription: description,
    ogImage: image,
    twitterTitle: title,
    twitterDescription: description,
    twitterImage: image,
})

useHead({
    link: [
        {rel: 'canonical', href: url}
    ],
});

</script>

<template>
<CmsPage v-if="category?.cmsPage" :content="category.cmsPage"/>
<LayoutNewsletterSignup />
</template>
